<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=sort,-id&expand=volunteerStatus,trainNum"
    entity-name="培训类型"
    add-btn="新增培训类型"
    hide-show-detail
    hide-delete
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="{ name: 1 }"
    :before-update="beforeUpdate"
    :before-edit="beforeEdit"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="培训类型">
        <a-input v-model="form.name" placeholder="输入培训类型查询" :maxLength="30" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="培训类型" prop="name">
        <a-input v-model="form.name" placeholder="请输入培训类型（最多30字）" :maxLength="30" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item label="可进行学习的志工状态（多选）" prop="volunteer_status">
        <a-select
          placeholder="选择志工状态"
          :options="volunStatusOptions"
          v-model="form.volunteer_status"
          mode="multiple"
          option-filter-prop="children"
          allowClear
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort">
        <a-input v-model="form.sort" placeholder="请输入数字，默认1为最前" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:action="{ item }">
      <a class="txt-btn danger" @click.stop="delRow(item)" v-action:delete v-if="item.trainNum == 0">删除</a>
    </template>
  </data-list>
</template>

<script>
import { volunStatusOptions } from "../../../common/hr/volunteer";

export default {
  name: "TrainType",
  data() {
    return {
      url: "/admin/volunteer-train-type",
      columns: [
        { title: "培训类型", dataIndex: "name" },
        {
          title: "可进行学习的志工状态",
          dataIndex: "volunteer_status",
          customRender: (text) => {
            return text?.split(",")?.map((status) => {
              let target = volunStatusOptions.find((option) => option.value == status);
              return <a-tag>{target.label}</a-tag>;
            });
          },
        },
        { title: "关联培训数量", dataIndex: "trainNum" },
        { title: "排序", dataIndex: "sort" },
      ],
      rules: {
        name: [{ required: true, message: "请输入培训类型", trigger: "blur" }],
        volunteer_status: [{ required: true, message: "请选择核志工状态", trigger: "change" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      volunStatusOptions,
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  methods: {
    beforeUpdate(form, fn) {
      form.volunteer_status = form.volunteer_status.join(",");
      fn(form);
    },
    beforeEdit(form, fn) {
      form.volunteer_status = form.volunteer_status?.split(",").map(Number);
      fn(form);
    },
    updateList() {
      this.dataList.updateList();
    },
    delRow(item) {
      this.$confirm({
        title: "提示",
        content: `确定删除此类型吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/${item.id}`,
            method: "DELETE",
          }).then(() => {
            this.$message.success("删除成功");
            this.updateList();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
